.user-block {
  display: flex;
  align-items: center;
}

.avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.username {
  margin-left: 8px;
  transition: color 0.3s;
}

.username:hover {
  color: var(--red-accent);
}
