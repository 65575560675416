@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kameron:wght@400;700&display=swap');

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-black);
}

* {
  font-family: Inter;
  font-size: 100%;
  font-weight: 400;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-black: #000000;
  --red-accent: #f24462;
  --content-width: 1280px;
  --black-accent: #0e0e0e;
  --gray-accent: #222222;
  --gray-accent2: #444444;
  --red-accent-light: #521721;
  --green-accent: #baefba;
  --gray-accent-light: #a7a7a7;
  --golden: #f7d146;
  --gray-accent3: #2d3030;
}

a {
  text-decoration: none;
  color: white;
}
