.container {
  width: 100%;
  display: flex;
}

.left {
  display: flex;
  width: 60%;
  flex-direction: column;
}

.right {
  color: white;
  width: 40%;
  height: 100%;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.table-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.table-content table {
  margin-top: 12px;
}

.table-content:last-child {
  margin-bottom: 0;
}

.won {
  color: var(--green-accent);
}

.loss {
  color: var(--red-accent);
}

.mvp {
  color: var(--golden);
}

.title {
  color: white;
  font-size: 22px;
  margin-left: 12px;
  margin-bottom: 4px;
  text-transform: uppercase;
  margin-top: 12px;
}

.title:first-child {
  margin-top: 0;
}

.subtitle {
  color: white;
  font-size: 20px;
  margin-left: 12px;
  margin-bottom: 4px;
}

.role-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
